.ai-component {
    height: 100vh;
    background: url('/src/img/black.png') repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
  }
  
  .overlay {
    background: rgba(0, 0, 0, 0.5); /* Adjust the opacity to your preference */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .centered-content {
    text-align: center;
    color: #fff; /* Adjust the text color to your preference */
  }
  
  .centered-content h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .centered-content p {
    font-size: 1.2em;
  }
  