.App {
  text-align: center;
}

.header {
  background-color: #000;
  color: #fff;
  padding: 20px;
}

.features {
  background-color: #000;
  color: #fff;
  padding: 40px;
}

.feature {
  margin-bottom: 30px;
}

.cta {
  background-color: #000;
  color: #fff;
  padding: 60px;
}
